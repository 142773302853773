//课程机构层级
export var learnLogo={
  type:7,
  label:"栏目图标",
  prop:"logo",
  activeValue:1,
  inactiveValue:0,
  changeFile:(file)=>{
    //图片改变
    vm.changeFile(file);
  }
}

