import {learnLogo} from "./learnData"
export var columnDrawer={
  list:[{//0
    type:2, //1普通输入框 2展示文字 3 textarea输入框 4选择下拉框 5数字输入框（排序）6开关状态是否类
    label:"上级栏目:",
    prop:"columnTypeName",
    className:"mFlex",
  },{//1
    type:1,
    label:"栏目名称:",
    prop:"name",
    placeholder:"请输入栏目名称",
    rules:[
      { required: true, message: '请输入栏目名称' },
    ]
  },{//2
    type:4,
    label:"栏目分类:",
    prop:"pid",
    placeholder:"请选择栏目分类",
    rules:[
      { required: true, message: '请选择栏目分类'},
    ],
    drop: {
      des:{ //循环列表里面对应的字段
        id:"id",
        name:"name"
      },
      model:{//应该赋值的返回的字段
        id:"columnTypeId",
        value:"columnTypeValue"
      },
      list:[]
    }
  },{
    type:5,
    label:"排列顺序",
    className:"mFlex",
    prop:"rank",
    rules:[
      { required: true, message: '请输入排列顺序'},
    ]
  },{
    type:6,
    label:"栏目状态",
    prop:"state",
    rules:[
      { required: true, message: '请选择层级状态'},
    ],
    className:"mFlex",
    activeValue:1,
    inactiveValue:-1
  },learnLogo]
};
